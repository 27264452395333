import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let currentYear = new Date().getFullYear();

    return (
      <Fragment>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="logo header-logo d-lg-block">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/footer-logo.svg`}
                      className="svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-4">
                <div className="google-review text-center">
                  <ul className="m-0 p-0 d-flex align-items-center list-unstyled justify-content-center">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/star-rating.svg`}
                        alt="Rating"
                      />
                    </li>
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/star-rating.svg`}
                        alt="Rating"
                      />
                    </li>
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/star-rating.svg`}
                        alt="Rating"
                      />
                    </li>
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/star-rating.svg`}
                        alt="Rating"
                      />
                    </li>
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/star-rating.svg`}
                        alt="Rating"
                      />
                    </li>
                  </ul>
                  <p>100+ 5-star Google Reviews</p>
                </div>
              </div>
              <div className="col-6 col-md-4  col-lg-4">
                <div className="social-icons">
                  <ul className="m-0 p-0 d-flex align-items-center list-unstyled">
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/facebook.svg`}
                          alt="Facebook"
                          className="svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/twitter.svg`}
                          alt="Twitter"
                          className="svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/linkedin.svg`}
                          alt="Lindin"
                          className="svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/instagram.svg`}
                          alt="Twitter"
                          className="svg"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="copyright-text text-center d-block">
                <p>© {currentYear} eAssist, Inc.</p>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default withRouter(Footer);
