import { Component } from 'react';
import S3FileUpload from 'react-s3';
import { S3Config } from '../config';

class CustomFunctions extends Component {
  validateEmail = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    return email.match(mailformat);
  };

  validateURL = (url) => {
    var pattern = /^[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])$/; //eslint-disable-line

    return url.match(pattern);
  };

  validateCard = (url) => {
    var pattern = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|((?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/; //eslint-disable-line

    return url.match(pattern);
  };

  uploadImage = async (uploadFileUrl) => {
    console.log('S3Config', S3Config);
    //eslint-disable-line
    const res = await S3FileUpload.uploadFile(uploadFileUrl, S3Config)
      .then((data) => {
        return data;
      })
      .catch((err) => console.error(err));
    return res;
  };

  loadScript = async (src) => {
    return new Promise(function (resolve, reject) {
      const ele = document.getElementById(src);
      if (ele) {
        ele.remove();
      }
      var script = document.createElement('script');
      script.id = src;
      script.src = src;
      script.type = 'text/javascript';
      script.async = false;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  };

  validatePhone = (number) => {
    var pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; //eslint-disable-line
    return number.match(pattern);
  };

  phoneMasking(value) {
    let newVal = value || value >= 0 ? value.toString().replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1$2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1$2$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1$2$3');
    }
    return newVal;
  }

  zipMasking(value) {
    let newVal = value || value >= 0 ? value.toString().replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,5})/, '$1');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1$2');
    } else {
      newVal = newVal.substring(0, 9);
      newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1$2');
    }
    return newVal;
  }

  onClickDropdown = (id, e) => {
    if (document.getElementById(id)) {
      const className = document.getElementById(id).className.split(' ');
      let element = document.getElementById(id);
      if (
        className.includes('open-select') &&
        (e._reactName === 'onMouseUp' ||
          e._reactName === 'onMouseLeave' ||
          e._reactName === 'onClick' ||
          e._reactName === 'onBlur')
      ) {
        element.classList.remove('open-select');
      } else if (e._reactName === 'onMouseDown' || e._reactName === 'onClick') {
        element.classList.add('open-select');
      }
    }
  };

  currencyMasking = (value) => {
    let newVal = value || value >= 0 ? value.toString().replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1,$2');
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1,$2');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1,$2');
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    } else if (newVal.length <= 8) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(
        /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/,
        '$1,$2,$3,$4'
      );
    }
    newVal = newVal.length > 0 ? '$' + newVal : newVal;
    return newVal;
  };

  convertToNumber = (value) => {
    const newVal = value ? value.toString().replace(/\D/g, '') : 0;
    return newVal;
  };

  numberMasking(value) {
    let newVal = value ? value.toString().replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    }
    return newVal;
  }

  validateMonth = (value) => {
    const currentMonth = new Date().getMonth();
    if (currentMonth + 1 < Number(value)) {
      return Number(value)
        .toString()
        .match(/^(0[1-9]|1[0-2])$/);
    }
    return null;
  };

  validateYear = (value) => {
    var d = new Date();
    var n = d.getFullYear().toString().substring(2, 4);
    let newVal = value.replace(/\D/g, '');
    if (this.convertToNumber(newVal) >= this.convertToNumber(n)) {
      return true;
    }
    return false;
  };

  getFormatedDate = (date, format) => {
    const arrFullMonth = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const arrShortMonth = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    format = format.toLowerCase();
    date = new Date(date);
    const getYear = date.getFullYear().toString();
    const getMonth = date.getMonth() + 1;
    const getDate = date.getDate().toString();
    format = format.replace('yyyy', getYear);
    format = format.replace('yy', getYear.substring(2));
    format = format.replace('mmmm', arrFullMonth[getMonth - 1]);
    format = format.replace('mmm', arrShortMonth[getMonth - 1]);
    format = format.replace('mm', getMonth < 10 ? '0' + getMonth : getMonth);
    format = format.replace(
      'dd',
      getDate.length === 1 ? '0' + getDate : getDate
    );
    return format;
  };

  clearLocalStorage = () => {
    localStorage.clear();
  };

  getDataFromLocalStorage = (keyname, stringify = false) => {
    if (keyname) {
      if (stringify) {
        return this.getStringifyDataFromLocalStorage(keyname);
      }
      return this.getStringDataFromLocalStorage(keyname);
    }
    return '';
  };

  getStringifyDataFromLocalStorage = (keyname) => {
    return localStorage.getItem(keyname)
      ? JSON.parse(localStorage.getItem(keyname))
      : '';
  };

  getStringDataFromLocalStorage = (keyname) => {
    return localStorage.getItem(keyname) || '';
  };

  setDataToLocalStorage = (keyname, data, stringify = false) => {
    if (keyname) {
      if (stringify && data && typeof data === 'object') {
        this.setStringifyDataToLocalStorage(keyname, data);
      } else {
        this.setStringDataToLocalStorage(keyname, data);
      }
    }
  };

  setStringifyDataToLocalStorage = (keyname, data) => {
    localStorage.setItem(keyname, JSON.stringify(data));
  };

  setStringDataToLocalStorage = (keyname, data) => {
    localStorage.setItem(keyname, data);
  };

  removeItemFromLocalStorage = (keyname) => {
    localStorage.removeItem(keyname);
  };

  checkIfUserExists = async () => {
    const token = this.getDataFromLocalStorage('usertoken', false);
    var userdata = await this.getDataFromLocalStorage('userdata', true);
    if ((!token && userdata.roleid !== 1) || (token && userdata.roleid !== 1)) {
      return true;
    }
    return false;
  };

  checkIsAdmin = async () => {
    const token = await this.getDataFromLocalStorage('usertoken', false);
    var userdata = await this.getDataFromLocalStorage('userdata', true);
    if ((!token && userdata.roleid !== 2) || (token && userdata.roleid !== 2)) {
      return true;
    }
    return false;
  };
}

export default new CustomFunctions();
