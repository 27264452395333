import React, { Component, Fragment } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from 'react-router-dom';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caption: 'Loading...',
    };
  }

  render() {
    const { caption } = this.state;
    const { showLoader, title, showApiLoader, needFullPage } = this.props;

    return (
      <Fragment>
        {showLoader && (
          <div className="SpinnerWrapper">
            <Spinner
              animation="border"
              role="status"
              size="l"
              variant="primary"
            ></Spinner>
            <br />
            <span className="SpinderCaption">{title ? title : caption}</span>
          </div>
        )}
        {showApiLoader ? (
          <div
            className={
              needFullPage ? 'loader-overlay full-loader' : 'loader-overlay'
            }
          >
            <div className="loader">
              <Spinner
                animation="border"
                role="status"
                size="l"
                variant="primary"
              ></Spinner>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default withRouter(Loader);
