import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact';
import customFunctions from '../../util/customFunctions';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleid: null,
      username: '',
      token: null,
    };
  }

  async componentDidMount() {
    const token = await customFunctions.getDataFromLocalStorage(
      'usertoken',
      false
    );
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleid: userdata.roleid,
      username: userdata.fullName,
      token: token,
    });
  }

  render() {
    const { username, roleid, token } = this.state;
    return (
      <Fragment>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-7 col-md-4">
                <div className="header-wrap">
                  <div className="logo header-logo d-lg-block">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                        className="svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="hamburger-menu-btn">
                  <button className="menu-toggle border-0 p-0 d-block">
                    <span className="d-block"></span>
                    <span className="d-block"></span>
                    <span className="d-block"></span>
                  </button>
                </div>
              </div>
              <div className="col-5 col-md-8">
                <div className="icons-nav">
                  <ul className="m-0 p-0 list-unstyled d-flex align-items-center justify-content-end">
                    {roleid == 1 && (
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/search.svg`}
                            className="svg"
                            alt=""
                          />
                        </a>
                      </li>
                    )}
                    {roleid == 1 && (
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.history.push('/summary-of-services');
                          }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/cart.svg`}
                            className="svg"
                            alt=""
                          />
                        </a>
                      </li>
                    )}
                    {token && username && (
                      <li>
                        <MDBDropdown>
                          <MDBDropdownToggle
                            style={{ border: 'none', background: '#fff' }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/user.svg`}
                              className="svg"
                              alt=""
                            />
                          </MDBDropdownToggle>
                          <MDBDropdownMenu basic className="profile-dropdown">
                            <MDBDropdownItem
                              onClick={() => {
                                this.props.history.push('/change-password');
                              }}
                            >
                              Change Password
                            </MDBDropdownItem>
                            <MDBDropdownItem
                              onClick={() => {
                                customFunctions.clearLocalStorage();
                                window.location.reload();
                                this.props.history.push('/login');
                              }}
                            >
                              Logout
                            </MDBDropdownItem>
                            <MDBDropdownItem divider />
                            <MDBDropdownItem disabled>
                              {username}
                              {roleid == 2 && '(Admin)'}
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

export default withRouter(Header);
