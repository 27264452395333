import { Component } from 'react';

export default class Api extends Component {
  static environment = 'dev';

  static baseUrl = Api.getBaseUrl();

  static endpointUrl = `${Api.baseUrl}`;

  static IMAGE_URL = Api.getImageBaseUrl();

  static checkuserUrl = `${Api.endpointUrl}users/checkuser/`;
  static registrationUrl = `${Api.endpointUrl}users/registration/`;
  static adminLogin = `${Api.endpointUrl}users/adminlogin`;
  static changePassword = `${Api.endpointUrl}users/changepassword`;

  static addserviceUrl = `${Api.endpointUrl}services/addservice/`;
  static getservicesUrl = `${Api.endpointUrl}services/getservices/`;
  static getservicebyidUrl = `${Api.endpointUrl}services/getservicebyid/`;
  static deleteserviceUrl = `${Api.endpointUrl}services/deleteservice/`;
  static updateserviceUrl = `${Api.endpointUrl}services/updateservice/`;
  static getservicesProvidedUrl = `${Api.endpointUrl}services/servicesprovided/`;
  static gettermsandconditionUrl = `${Api.endpointUrl}services/termsandconditions/`;

  static addcartUrl = `${Api.endpointUrl}cart/addcart/`;
  static getcartUrl = `${Api.endpointUrl}cart/getcart/`;
  static deleteallcartUrl = `${Api.endpointUrl}cart/deleteallcart/`;
  static deletecartitemUrl = `${Api.endpointUrl}cart/deletecartitem`;
  static updatecartUrl = `${Api.endpointUrl}cart/updatecart`;
  static addcartpromocode = `${Api.endpointUrl}cart/addcartpromocode`;

  static generalUrl = `${Api.endpointUrl}checkout/general/`;
  static contactsUrl = `${Api.endpointUrl}checkout/contacts`;
  static requirementsandquestionsUrl = `${Api.endpointUrl}checkout/requirementsandquestions`;
  static shipbilladdressUrl = `${Api.endpointUrl}checkout/shipbilladdress`;
  static servicerequirementsUrl = `${Api.endpointUrl}checkout/servicerequirements`;
  static serviceagreementdataUrl = `${Api.endpointUrl}checkout/serviceagreementdata`;
  static practisesoftwarelistUrl = `${Api.endpointUrl}checkout/practisesoftwarelist`;
  static prefixUrl = `${Api.endpointUrl}checkout/prefix`;
  static removeadditionalcontactUrl = `${Api.endpointUrl}checkout/removeadditionalcontact`;

  static listorders = `${Api.endpointUrl}order​/listorders`;
  static vieworder = `${Api.endpointUrl}order/vieworder`;
  static addorder = `${Api.endpointUrl}order/addorder`;
  static updateorderstatus = `${Api.endpointUrl}order/updateorderstatus`;
  static updateorder = `${Api.endpointUrl}order​/updateorder`;

  static addlocationsUrl = `${Api.endpointUrl}location/addlocations`;
  static addlocationUrl = `${Api.endpointUrl}location/addlocation`;
  static getlocationUrl = `${Api.endpointUrl}location/getlocation`;
  static removelocationUrl = `${Api.endpointUrl}location/removelocation`;

  static checkpromocodeUrl = `${Api.endpointUrl}promocode/checkpromocode`;
  static getallpromocodeUrl = `${Api.endpointUrl}promocode/getallpromocode`;
  static addpromocodeUrl = `${Api.endpointUrl}promocode/addpromocode`;
  static updatepromocodeUrl = `${Api.endpointUrl}promocode/updatepromocode`;
  static updatestatusUrl = `${Api.endpointUrl}promocode/updatestatus`;
  static deletepromocodeUrl = `${Api.endpointUrl}promocode/deletepromocode`;

  static listinvoiceUrl = `${Api.endpointUrl}invoice/listinvoice`;
  static viewinvoiceUrl = `${Api.endpointUrl}invoice/viewinvoice`;

  static adddefaultsettingUrl = `${Api.endpointUrl}defaultsetting/adddefaultsetting`;
  static updatedefaultsettingUrl = `${Api.endpointUrl}defaultsetting/updatedefaultsetting`;
  static getdefaultsettingbyidUrl = `${Api.endpointUrl}defaultsetting/getdefaultsettingbyid`;
  static getdefaultsettinglistUrl = `${Api.endpointUrl}defaultsetting/getdefaultsettinglist`;
  static getdefaultsettingStatesUrl = `${Api.endpointUrl}defaultsetting/getstates`;

  static getreviewlistUrl = `${Api.endpointUrl}review/getreviewlist`;
  static getreviewbyidUrl = `${Api.endpointUrl}review/getreviewbyid`;
  static addreviewUrl = `${Api.endpointUrl}review/addreview`;
  static updatereviewUrl = `${Api.endpointUrl}review/updatereview`;

  static downloadyouragreementsUrl = `${Api.endpointUrl}invoice/downloadyouragreements`;
  static downloadpdfUrl = `${Api.endpointUrl}invoice​/downloadpdf`;

  static paymentUrl = `${Api.endpointUrl}payment/startpayment`;
  static addPaymentResponseUrl = `${Api.endpointUrl}payment/addpaymentresponse`;
  static downloadagreementlinkUrl = `${Api.endpointUrl}order/downloadagreementlink`;
  static getquestionsbyserviceidUrl = `${Api.endpointUrl}services/getquestionsbyserviceid`;
  static addanswerdataUrl = `${Api.endpointUrl}services/addanswerdata`;
  static getquestionandanswerUrl = `${Api.endpointUrl}services/getquestionandanswer`;
  static generateuploadpdfUrl = `${Api.endpointUrl}invoice/generateuploadpdf`;
  static addQuestionUrl = `${Api.endpointUrl}services/addquestions`;
  static updateQuestionUrl = `${Api.endpointUrl}services/putquestions`;

  static downloadSpeedTest = `${Api.endpointUrl}speedtest/downloadspeed`;
  static uploadSpeedTest = `${Api.endpointUrl}speedtest/uploadspeed`;
  static addDeepLink = `${Api.endpointUrl}checkout/adddeeplink`;
  static checkDeepLink = `${Api.endpointUrl}checkout/checkdeeplink`;

  static getGeneralQuestions = `${Api.endpointUrl}generalquestions/getgeneralquestions`;
  static addGeneralQuestion = `${Api.endpointUrl}generalquestions/addgeneralquestions`;
  static updateGeneralQuestion = `${Api.endpointUrl}generalquestions/putgeneralquestions`;
  static deleteGeneralQuestion = `${Api.endpointUrl}generalquestions/deletegeneralquestion`;
  static getGeneralQuestionAns = `${Api.endpointUrl}generalquestions/getgeneralquestionandanswer`;
  static addGeneralAnswer = `${Api.endpointUrl}generalquestions/addgeneralanswerdata`;
  static updateGeneralAnswer = `${Api.endpointUrl}generalquestions/updategeneralanswer`;

  constructor(props) {
    super(props);
    this.state = {};
    this.getBaseUrl = this.getBaseUrl.bind(this);
  }

  // Get base URL of APIs
  static getBaseUrl() {
    let url = '';
    switch (Api.environment) {
      case 'production':
        url = 'https://gann8va344.execute-api.us-east-1.amazonaws.com/prod/';
        break;
      default:
        url = 'https://gann8va344.execute-api.us-east-1.amazonaws.com/dev/';
        break;
    }
    return url;
  }

  // Get Image base URL of APIs
  static getImageBaseUrl() {
    let url = '';
    switch (Api.environment) {
      case 'production':
        // url = 'https://localhost/media/';
        url = 'http://hub.eassist.me/File/FileResource/';
        break;
      // Default: development server
      default:
        // url = 'https://localhost/media/';
        url = 'http://hub.eassist.me/File/FileResource/';
        break;
    }
    return url;
  }
}
