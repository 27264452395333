import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Routes from './routes';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import customFunctions from './util/customFunctions';
import Api from './util/api';
import AuthApi from './util/authApi';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    var url = window.location.href;
    var arr = url.split('/');
    var route = arr[3];
    var deeplinktoken = arr[4];
    if (route == 'check-deeplink' && deeplinktoken) {
      this.checkDeepLink(deeplinktoken);
    }

    // const token = await customFunctions.getDataFromLocalStorage(
    //   'usertoken',
    //   false
    // );
    // var userdata = await customFunctions.getDataFromLocalStorage(
    //   'userdata',
    //   true
    // );
    // if (userdata.roleId === 2) {
    //   let exists = await customFunctions.checkIsAdmin();
    //   if(exists) this.props.history.push('/login');
    // }
    // if (!token && route !== 'login') {
    //   this.props.history.push('/get-started');
    //   return;
    // }
  };

  checkDeepLink = async (deeplinktoken) => {
    const endPoint = `${Api.checkDeepLink}/${deeplinktoken}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data == null) {
      this.props.history.push('/get-started');
    }
    if (data && data.data && data.data.currenturl) {
      window.location = data.data.currenturl;
      await customFunctions.setDataToLocalStorage(
        'usertoken',
        data.data.token,
        false
      );
      await customFunctions.setDataToLocalStorage(
        'userdata',
        data.data.userData,
        true
      );
    }
  };

  render() {
    const childProps = {};
    return (
      <Fragment>
        <Header />
        <Routes childProps={childProps} />
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(App);
