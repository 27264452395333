import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './components/async.component';

const AsyncHome = asyncComponent(() => import('./screens/Home/index'));
const AsyncAuthentication = asyncComponent(() =>
  import('./screens/Authentication')
);
const AsyncNotFound = asyncComponent(() =>
  import('./screens/NotFound/not-found.component')
);
const AsyncCheckoutGeneral = asyncComponent(() =>
  import('./screens/Checkout/general')
);
const AsyncCheckoutContacts = asyncComponent(() =>
  import('./screens/Checkout/contacts')
);
const AsyncCheckoutLocations = asyncComponent(() =>
  import('./screens/Checkout/locations')
);
// const AsyncCheckoutSoftwareAndTech = asyncComponent(() =>
//   import('./screens/Checkout/softwareAndTech')
// );
const AsyncCheckoutServiceRequirements = asyncComponent(() =>
  import('./screens/Checkout/serviceRequirements')
);
// const AsyncCheckoutQuestions = asyncComponent(() =>
//   import('./screens/Checkout/questions')
// );
// const AsyncCheckoutShipping = asyncComponent(() =>
//   import('./screens/Checkout/shipping')
// );
const AsyncCheckoutPaymentDetails = asyncComponent(() =>
  import('./screens/Checkout/paymentDetails')
);
const AsyncCheckoutServiceAgreement = asyncComponent(() =>
  import('./screens/Checkout/serviceAgreement')
);
const AsyncSummaryOfServices = asyncComponent(() =>
  import('./screens/SummaryOfServices')
);
const AsyncOrderConfirmation = asyncComponent(() =>
  import('./screens/OrderConfirmation')
);
const AsyncInternetSpeed = asyncComponent(() =>
  import('./screens/InternetSpeed/index')
);
const AsyncInternetSpeedEnd = asyncComponent(() =>
  import('./screens/InternetSpeed/internetSpeedEnd')
);
const AsyncServicesList = asyncComponent(() =>
  import('./screens/Service/index')
);
const AsyncServicesAddEdit = asyncComponent(() =>
  import('./screens/Service/addEdit')
);
const AsyncSubQuestions = asyncComponent(() =>
  import('./screens/SubQuestions')
);
const AsyncSubQuestionAddEdit = asyncComponent(() =>
  import('./screens/SubQuestions/addEdit')
);
const AsyncAdminLogin = asyncComponent(() =>
  import('./screens/Authentication/adminLogin')
);
const AsyncChangePassword = asyncComponent(() =>
  import('./screens/Authentication/changePassword')
);
const AsyncGeneralQuestions = asyncComponent(() =>
  import('./screens/GeneralQuestions')
);
const AsyncAddGeneralQuestions = asyncComponent(() =>
  import('./screens/GeneralQuestions/add')
);
const AsyncAddGeneralSubQuestions = asyncComponent(() =>
  import('./screens/GeneralQuestions/subquestion')
);
const AsyncDefaultSettings = asyncComponent(() =>
  import('./screens/DefaultSettings')
);
const AsyncReviews = asyncComponent(() => import('./screens/Reviews'));
const AsyncReviewsEdit = asyncComponent(() => import('./screens/Reviews/add'));

const AppRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps }) => {
  return (
    <Switch>
      <AppRoute path="/" exact component={AsyncHome} props={childProps} />
      <AppRoute
        path="/login"
        exact
        component={AsyncAdminLogin}
        props={childProps}
      />
      <AppRoute
        path="/change-password"
        exact
        component={AsyncChangePassword}
        props={childProps}
      />
      <AppRoute
        path="/get-started"
        exact
        component={AsyncAuthentication}
        props={childProps}
      />
      <AppRoute
        path="/checkout-general"
        exact
        component={AsyncCheckoutGeneral}
        props={childProps}
      />
      <AppRoute
        path="/checkout-contacts"
        exact
        component={AsyncCheckoutContacts}
        props={childProps}
      />
      <AppRoute
        path="/checkout-locations"
        exact
        component={AsyncCheckoutLocations}
        props={childProps}
      />
      {/* <AppRoute
        path="/checkout-swtech"
        exact
        component={AsyncCheckoutSoftwareAndTech}
        props={childProps}
      /> */}
      <AppRoute
        path="/checkout-serreq"
        exact
        component={AsyncCheckoutServiceRequirements}
        props={childProps}
      />
      {/* <AppRoute
        path="/checkout-questions"
        exact
        component={AsyncCheckoutQuestions}
        props={childProps}
      />
      <AppRoute
        path="/checkout-shipping"
        exact
        component={AsyncCheckoutShipping}
        props={childProps}
      /> */}
      <AppRoute
        path="/checkout-paydetails"
        exact
        component={AsyncCheckoutPaymentDetails}
        props={childProps}
      />
      <AppRoute
        path="/checkout-seragrmt"
        exact
        component={AsyncCheckoutServiceAgreement}
        props={childProps}
      />
      <AppRoute
        path="/summary-of-services"
        exact
        component={AsyncSummaryOfServices}
        props={childProps}
      />
      <AppRoute
        path="/order-confirmation"
        exact
        component={AsyncOrderConfirmation}
        props={childProps}
      />
      <AppRoute
        path="/internet-speed"
        exact
        component={AsyncInternetSpeed}
        props={childProps}
      />
      <AppRoute
        path="/internet-speed-end"
        exact
        component={AsyncInternetSpeedEnd}
        props={childProps}
      />
      <AppRoute
        path="/services"
        exact
        component={AsyncServicesList}
        props={childProps}
      />
      <AppRoute
        path="/services/add"
        exact
        component={AsyncServicesAddEdit}
        props={childProps}
      />
      <AppRoute
        path="/services/edit/:id"
        exact
        component={AsyncServicesAddEdit}
        props={childProps}
      />
      <AppRoute
        path="/subquestions"
        exact
        component={AsyncSubQuestions}
        props={childProps}
      />
      <AppRoute
        path="/subquestions/add"
        exact
        component={AsyncSubQuestionAddEdit}
        props={childProps}
      />
      <AppRoute
        path="/subquestions/edit"
        exact
        component={AsyncSubQuestionAddEdit}
        props={childProps}
      />
      <AppRoute
        path="/general-questions"
        exact
        component={AsyncGeneralQuestions}
        props={childProps}
      />
      <AppRoute
        path="/general-question/:type"
        exact
        component={AsyncAddGeneralQuestions}
        props={childProps}
      />
      <AppRoute
        path="/general-subquestion"
        exact
        component={AsyncAddGeneralSubQuestions}
        props={childProps}
      />
      <AppRoute
        path="/default-settings"
        exact
        component={AsyncDefaultSettings}
        props={childProps}
      />
      <AppRoute
        path="/reviews"
        exact
        component={AsyncReviews}
        props={childProps}
      />
      <AppRoute
        path="/reviews/:id"
        exact
        component={AsyncReviewsEdit}
        props={childProps}
      />
      {/* <AppRoute component={AsyncNotFound} /> */}
    </Switch>
  );
};
