import React, { Component } from 'react';
import Loader from './Loader';
// import customFunctions from '../util/customFunctions';
import { withRouter } from 'react-router-dom';
// import '../assets/css/bootstrap.min.css';
// import '../assets/css/slick.css';
// import '../assets/css/style-new.css';
// import './../assets/css/style.css';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    // componentWillMount = async () => {
    //   var baseurl = process.env.PUBLIC_URL;
    //   if (
    //     document.readyState === 'complete' ||
    //     document.readyState === 'loading'
    //   ) {
    //     await customFunctions.loadScript(
    //       baseurl + '/assets/js/jquery-3.5.1.min.js'
    //     );
    //     await customFunctions.loadScript(
    //       baseurl + '/assets/js/bootstrap.min.js'
    //     );
    //     // await customFunctions.loadScript(baseurl + "/assets/js/slick.min.js");
    //     // await customFunctions.loadScript(baseurl + "/assets/js/custom.js");
    //   }
    // };

    render() {
      const { component } = this.state;
      const C = component;
      return C ? <C {...this.props} /> : <Loader showLoader />;
    }
  }

  return withRouter(AsyncComponent);
}
