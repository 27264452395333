import axios from 'axios';
import customFunctions from '../../util/customFunctions';

export default {
  setupInterceptors: async () => {
    await axios.interceptors.request.use(
      async (config) => {
        config.headers['Content-Type'] = 'application/json';
        const token = await customFunctions.getDataFromLocalStorage(
          'usertoken',
          false
        );
        if (token) config.headers['x-access-token'] = `${token}`; // eslint-disable-line
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    await axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const data = await error.response;
        if (
          data &&
          (data.status === 401 || data.statusText === 'Unauthorized')
        ) {
          await customFunctions.clearLocalStorage();
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response);
      }
    );
  },
};
