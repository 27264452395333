export const S3Config = {
  bucketName: 'shoppingcartstoragebucket',
  dirName: 'images',
  accessKeyId: process.env.REACT_APP_access_key,
  secretAccessKey: process.env.REACT_APP_secret_access_key,
  region: 'us-east-1',
};

export const paypal = {
  client: {
    sandbox:
      'AaTVCpPO30fKHnrbNU9yhcvJHOyBthq4OVjIt4KS1iooQ2lU_EkaYIrQrAx1rrqO-IjV3d31fM-aX0nN',
    production:
      'AaTVCpPO30fKHnrbNU9yhcvJHOyBthq4OVjIt4KS1iooQ2lU_EkaYIrQrAx1rrqO-IjV3d31fM-aX0nN',
  },
  env: 'sandbox',
};
